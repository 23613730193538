.share {
  &-section {
    position: relative;
    width: (614px/2);
    padding: (73px/2) 0 (63px/2);
    @include mq(tablet) {
      width: 320px;
      padding: 36px 0 39px;
    }
  }
  &-title {
    font-size: (42px/2);
    font-weight: 600;
    color: $point-color;
    text-align: center;
    @include mq(tablet) {
      font-size: 24px;
    }
  }
  &-cont {
    margin-top: (50px/2);
    @include mq(tablet) {
      margin-top: 36px;
    }
    &-url {
      width: (466px/2);
      margin: 0 auto;
      @include mq(tablet) {
        width: 257px;
      }
    }
    &-btn {
      position: relative;
      display: block;
      width: 100%;
      text-align: left;
      border-bottom: 1px solid rgba($point-color, .6);
      &:before,
      &:after {
        position: absolute;
        width: 1px;
        left: 0;
        bottom: 0;
        height: (10px/2);
        background-color: rgba($point-color, .6);
        content: '';
      }
      &:after {
        left: auto;
        right: 0;
      }
    }
    &-box {
      position: relative;
      display: inline-block;
      height: (40px/2);
      font-size: 0;
      @include mq(tablet) {
        height: 22px;
      }
    }
    &-text {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      margin: 0 1px;
      display: inline-block;
      height: (40px/2);
      font-size: (24px/2);
      font-weight: 400;
      color: $black-color;
      cursor: pointer;
      background-color: rgba($point-color, .2);
      @include mq(tablet) {
        height: 22px;
        font-size: 14px;
      }
      &::selection {
        background-color: transparent;
        color: inherit;
        text-shadow: none;
      }
      &::-moz-selection {
        background-color: transparent;
        color: inherit;
        text-shadow: none;
      }
      &::-webkit-selection {
        background-color: transparent;
        color: inherit;
        text-shadow: none;
      }
    }
    &-space {
      display: inline-block;
      width: auto;
      height: (40px/2);
      margin: 0 1px;
      font-size: (24px/2);
      font-weight: 400;
      color: transparent;
      visibility: hidden;
      pointer-events: none;
      @include opacity(0);
      @include mq(tablet) {
        height: 22px;
        font-size: 14px;
      }
    }
    &-desc {
      margin-top: (20px/2);
      font-size: (24px/2);
      font-weight: 400;
      color: #999;
      text-align: left;
      letter-spacing: -.01em;
      @include mq(tablet) {
        margin-top: 10px;
        font-size: 12px;
      }
    }
  }
  &-method {
    margin-top: (50px/2);
    @include mq(tablet) {
      margin-top: 29px;
    }
    &__list {
      font-size: 0;
      text-align: center;
      @include clearfix();
    }
    &__item {
      display: inline-block;
      & ~ & {
        margin-left: (50px/2);
        @include mq(tablet) {
          margin-left: 24px;
        }
      }
    }
    &__txt {
      display: block;
      margin-top: 13px;
      font-size: (22px/2);
      color: #999;
      @include mq(tablet) {
        margin-top: 7px;
        font-size: 11px;
      }
    }
  }
  .mail-this,
  .facebook-this,
  .tweet-this {
    display: block;
    width: (93px/2);
    height: (93px/2);
    font-size: 0;
    text-indent: -100%;
    overflow: hidden;
    @include border-radius(6px);
    @include mq(tablet) {
      width: 48px;
      height: 48px;
    }
    & a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .mail-this {
    background-color: $email-color;
    @include icon-style('ic-email.svg', (39px/2) auto, no-repeat, center center);
    @include mq(tablet) {
      background-size: 20px auto;
    }
  }
  .facebook-this {
    background-color: $fb-color;
    @include icon-style('ic-facebook-w-act.svg', (16px/2) auto, no-repeat, center center);
    @include mq(tablet) {
      background-size: 8px auto;
    }
  }
  .tweet-this {
    background-color: $twitter-color;
    @include icon-style('ic-twitter.svg', (36px/2) auto, no-repeat, center center);
    @include mq(tablet) {
      background-size: 18px auto;
    }
  }
}