.recruit {
  position: relative;
  @include mq(tablet) {
    margin-top: -17px;
  }
  &:before {
    position: absolute;
    display: block;
    width: 1px;
    height: (101px/2);
    top: 0;
    left: 0;
    right: 0;
    margin: (12.7px/2) auto 0;
    background-color: rgba($point-color, .5);
    content: '';
    @include mq(tablet) {
      height: 83px;
      margin: 0 auto;
      top: 93.3px;
    }
    @include mq(desktop) {
      height: 104px;
      top: 116px;
    }
  }
  &:after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('../img/bg-recruit-mob.png');
    background-size: 100% auto;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: -1;
    content: '';
    @include mq(tablet) {
      background-image: url('../img/bg-recruit-tablet.png');
      background-size: auto;
    }
    @include mq(desktop) {
      background-image: url('../img/bg-recruit-pc.png');
    }
    @include mq(wide) {
      background-image: url('../img/bg-recruit-wide.png');
    }
  }
  &-wrapper {
    position: relative;
    padding-top: (177px/2);
    padding-bottom: (86px/2);
    height: 100%;
    font-size: 0;
    text-align: center;
    @include mq(tablet) {
      padding-top: 208.3px;
      padding-bottom: 164.7px;
    }
    @include mq(desktop) {
      padding-top: 260px;
      padding-bottom: 260px;
    }
  }
  &-name {
    width: (82px/2);
    height: 10px;
    background: url('../img/logo/img-text-recruit.svg') no-repeat center center;
    background-size: 100% auto;
    @include mq(tablet) {
      width: 49px;
      height: 6px;
    }
    @include mq(desktop) {
      width: 62px;
      height: 7px;
    }
  }
  &-area {
    @include mq(tablet) {
      position: relative;
      margin-top: 113px;
      text-align: left;
    }
    @include mq(desktop) {
      margin-top: 145px;
    }
  }
  &-description {
    display: block;
    width: auto;
    margin: auto;
    padding: 0 (32px/2);
    @include mq(tablet) {
      position: relative;
      display: inline-block;
      padding-left: $tablet-padding;
      padding-right: 0;
      z-index: 60;
    }
    @include mq(desktop) {
      padding-left: $desktop-padding;
    }
    @include mq(wide) {
      padding-left: $wide-padding;
    }
  }
  &-button {
    margin-top: (70px/2);
    text-align: left;
    @include mq(tablet) {
      margin-top: 39px;
    }
    @include mq(desktop) {
      margin-top: 49px;
    }
  }
}
//slide
.recruit {
  &-content {
    position: relative;
    padding: (55px/2) 0 0 (42px/2);
    @include mq(tablet) {
      position: absolute;
      top: 4px;
      left: percentage(361/768);
      width: 100%;
      padding: 0;
    }
    @include mq(desktop) {
      top: -7px;
      left: percentage(625/1440);
    }
    @include mq(wide) {
      left: 655px;
    }
  }
  .swiper-container {
    position: relative;
    overflow: visible;
  }
  .swiper-slide {
    width: (500px/2);
    @include mq(tablet) {
      width: 324px;
    }
    @include mq(desktop) {
      width: 490px;
    }
  }
  &-inner {
    position: relative;
    z-index: 10;
    transform: translate3D(0,0,0);
  }
  &-slide {
    margin-right: (32px/2);
    position: relative;
    cursor: pointer;
    @include mq(tablet) {
      margin-right: 18px;
    }
    @include mq(desktop) {
      margin-right: 54px;
    }
    &.swiper-slide-active {
      z-index: 100;
      @include opacity(1);
    }
    &.next-sibling {
      @include opacity(1);
    }
    &.prev-sibling {
      cursor: default;
      pointer-events: none;
      @include opacity(0);
    }
    &-deco {
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      margin-top: (-12px/2);
      margin-left: (-12px/2);
      width: (252px/2);
      height: (134px/2);
      background-color: #2a2b2e;
      z-index: 5;
      @include opacity(0);
      @include mq(tablet) {
        width: 139px;
        height: 74px;
        margin-top: -6px;
        margin-left: -6px;
      }
      @include mq(desktop) {
        width: 210px;
        height: 112px;
        margin-top: -10px;
        margin-left: -10px;
      }
      .swiper-slide-active & {
        transform: translate3d(0,0,0);
        @include opacity(1);
      }
    }
    &-img {
      position: relative;
      box-shadow: (-9.5px/2) (15.3px/2) (40px/2) 0 rgba(11,12,17,.2);
      transition: all 1000ms;
    }
    &-under {
      position: absolute;
      left: 0;
      bottom: 0;
      width: percentage(482/500);
      height: (98px/2);
      text-align: left;
      box-sizing: border-box;
      z-index: 50;
      @include mq(tablet) {
        height: 56px;
      }
      @include mq(desktop) {
        height: 86px;
      }
      &__bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #27292d;
        transition: all 300ms;
        @include opacity(.9);
      }
      &__button {
        position: relative;
        height: 100%;
        padding-left: (30px/2);
        font-size: 0;
        z-index: 1;
        @include mq(tablet) {
          padding-left: 17px;
        }
        @include mq(desktop) {
          padding-left: 25px;
        }
      }
      &__title {
        display: inline-block;
        font-size: (36px/2);
        font-weight: 500;
        color: $white-color;
        line-height: (98px/2);
        vertical-align: middle;
        @include opacity(.5);
        @include mq(tablet) {
          font-size: 20px;
          line-height: 56px;
        }
        @include mq(desktop) {
          font-size: 30px;
          line-height: 86px;
        }
        .swiper-slide-active & {
          @include opacity(1);
        }
      }
      &__sub {
        display: inline-block;
        width: 0;
        font-size: (36px/2);
        font-weight: 500;
        color: $white-color;
        line-height: (98px/2);
        overflow: hidden;
        white-space: nowrap;
        vertical-align: middle;
        transition: all 300ms;
        @include opacity(.5);
        @include mq(tablet) {
          font-size: 20px;
          line-height: 56px;
        }
        @include mq(desktop) {
          font-size: 30px;
          line-height: 86px;
        }
        .swiper-slide-active & {
          @include opacity(1);
        }
      }
      &__icon {
        display: inline-block;
        margin-top: -3px;
        margin-left: (11px/2);
        width: (26px/2);
        height: (26px/2);
        vertical-align: middle;
        @include icon-style('ic-plus-white-off.svg', 100% auto, no-repeat, center center);
        @include mq(tablet) {
          width: 15px;
          height: 15px;
          margin-left: 7px;
        }
        @include mq(desktop) {
          width: 22px;
          height: 22px;
          margin-left: 11px;
        }
        .swiper-slide-active & {
          @include icon-style('ic-plus-blue-on.svg', 100% auto, no-repeat, center center);
        }
        .recruit-inner:hover & {
          @include icon-style('ic-arrow-01.svg', 100% auto, no-repeat, center center);
        }
      }
    }
    .recruit-inner:hover &-under__bg {
      background-color: $point-color;
    }
    .recruit-inner:hover &-under__sub {
      margin-left: 5px;
      width: 63px;
      @include mq(tablet) {
        width: 70px;
      }
      @include mq(desktop) {
        width: 104px;
      }
    }
    &-dim {
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      z-index: 40;
      transition: all 500ms;
      @include opacity(.4);
      .swiper-slide-active & {
        @include opacity(0);
      }
    }
  }
  &-slide-controller {
    position: absolute;
    left: (30px/2);
    bottom: (-69px/2);
    font-size: 0;
    text-align: left;
    @include mq(tablet) {
      left: -8.5px;
      bottom: -56px;
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    position: static;
    display: inline-block;
    width: (56px/2);
    height: (56px/2);
    margin-top: auto;
    vertical-align: middle;
    @include icon-style('ic-m-arrow-r-nor.svg', (18px/2) auto, no-repeat, center center);
    transition: all 300ms;
    @include opacity(.6);
    @include mq(tablet) {
      background-size: 11px auto;
    }
    &:hover {
      @include opacity(1);
    }
  }
  .swiper-button-prev {
    transform: scale(-1, 1);
  }
  .swiper-pagination {
    &-fraction {
      position: static;
      display: inline-block;
      width: (67px/2);
      color: #ebebeb;
      font-family: $montserrat-font;
      font-size: 11px;
      font-weight: 600;
      letter-spacing: 0.044em;
      vertical-align: middle;
      text-align: center;
      @include mq(tablet) {
        font-size: 12px;
      }
    }
    &-current {
      color: $point-color;
    }
    &-total {
      color: $desc-color;
    }
  }
}