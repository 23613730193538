.service {
  position: relative;
  &:before {
    position: absolute;
    display: block;
    width: 1px;
    height: (250px/2);
    top: (72px/2);
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: rgba($point-color, .5);
    content: '';
    @include mq(tablet) {
      top: (83px/2);
      height: 73px;
    }
    @include mq(desktop) {
      top: (118px/2);
      height: 104px;
    }
  }
  &-wrapper {
    position: relative;
    padding-top: (394px/2);
    font-size: 0;
    z-index: 10;
    @include mq(tablet) {
      padding-top: 156px;
    }
    @include mq(desktop) {
      padding-top: 222px;
    }
  }
  &-name {
    width: (131px/2);
    height: 10px;
    background: url('../img/logo/img-text-ourservice.svg') no-repeat center center;
    background-size: 100% auto;
    @include mq(tablet) {
      width: 80px;
      height: 6px;
    }
    @include mq(desktop) {
      width: 99px;
      height: 7px;
    }
  }
  &-description {
    margin: auto;
  }
  &-button {
    margin-top: (89px/2);
    text-align: center;
    @include mq(tablet) {
      margin-top: 55px;
    }
    @include mq(desktop) {
      margin-top: 67px;
    }
  }

  &-footer {
    margin-top: (97px/2);
    @include mq(tablet) {
      margin-top: 28px;
    }
    @include mq(desktop) {
      margin-top: 47px;
    }
    @include mq(wide) {
      margin-top: 72px;
    }
    &__upper {
      position: relative;
      font-size: 0;
      @include mq(desktop) {
        text-align: right;
      }
      .bg {
        position: absolute;
        background-color: #f8f8f8;
        left:0;
        right:0;
        bottom: 0;
        height: 0;
        @include mq(tablet) {
          height: 78px;
        }
        @media all and (min-width: 834px) and (max-width: 1199px){
          height: 121px;
        }
        @include mq(desktop) {
          height: 94px;
        }
        @include mq(wide) {
          height: 132px;
        }

      }
    }
    &__img {
      @include mq(tablet) {
        margin: 0 73px 0 84px;
      }
      @include mq(desktop) {
        display: inline-block;
        width: percentage(789/1440);
        max-width: 789px;
        margin-right: percentage(41/1440);
      }
      @include mq(wide) {
        width: percentage(1013/1920);
        max-width: 1013px;
        margin-right: percentage(134/1920);
      }
      img {
        position: relative;
        z-index: 1;
      }
    }
    &__under {
      width: 100%;
      height: (232px/2);
      background-color: #111418;
      @include mq(tablet) {
        height: 134px;
      }
      @include mq(desktop) {
        height: 168px;
      }
      .layout-wrapper {
        height: 100%;
        @include mq(tablet) {
          padding: 0 $tablet-padding;
        }
        @include mq(desktop) {
          padding: 0 $desktop-padding;
        }
        @include mq(wide) {
          padding: 0 $wide-padding;
        }
      }
    }
  }
}
// service slide
.service {
  .swiper-container {
    width: 100%;
    height: 100%;
    font-size: 0;
  }
  &-slide {
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: 0;
    &:before {
      display: inline-block;
      width: 1px;
      height: 100%;
      margin-left: -1px;
      vertical-align: middle;
      content: '';
    }
  }
  &-slide-controller {
    display: block;
    @include mq(tablet) {
      display: none;
    }
  }
  &-box {
    display: inline-block;
    vertical-align: middle;
    &__icon {
      display: inline-block;
      width: (112px/2);
      height: (112px/2);
      vertical-align: middle;
      @include mq(tablet) {
        width: 50px;
        height: 50px;
      }
      @include mq(desktop) {
        width: 56px;
        height: 56px;
      }
      &[data-icon=video] {
        @include icon-style('ic-service-video.svg', (96px/2) auto, no-repeat, center center);
        @include mq(tablet) {
          background-size: 44px auto;
        }
        @include mq(desktop) {
          background-size: 48px auto;
        }
      }
      &[data-icon=wallet] {
        @include icon-style('ic-service-wallet.svg', (101px/2) auto, no-repeat, center center);
        @include mq(tablet) {
          background-size: 42px auto;
        }
        @include mq(desktop) {
          background-size: 47px auto;
        }
      }
      &[data-icon=handshake] {
        @include icon-style('ic-service-handshake.svg', (108px/2) auto, no-repeat, center center);
        @include mq(tablet) {
          background-size: 49px auto;
        }
        @include mq(desktop) {
          background-size: 56px auto;
        }
      }
    }
    &__text {
      position: relative;
      display: inline-block;
      margin-left: (80px/2);
      color: rgba($white-color, .8);
      vertical-align: middle;
      text-align: left;
      @include mq(tablet) {
        margin-left: 11px;
      }
      @include mq(desktop) {
        margin-left: 16px;
      }
      h4 {
        font-size: (26px/2);
        font-weight: 400;
        letter-spacing: .13em;
        @include mq(tablet) {
          font-size: 13px;
        }
        @include mq(desktop) {
          font-size: 14px;
        }
      }
      div {
        padding-top: (26px/2);
        font-size: (48px/2);
        font-weight: 600;
        letter-spacing: .24em;
        @include mq(tablet) {
          padding-top: 10px;
          font-size: 20px;
          letter-spacing: .2em;
        }
        @include mq(desktop) {
          padding-top: 8px;
          font-size: 25px;
          letter-spacing: .25em;
        }
      }
      em {
        font-size: (39px/2);
        font-weight: 700;
        letter-spacing: .19em;
        vertical-align: middle;
        @include mq(tablet) {
          font-size: 16px;
          letter-spacing: .16em;
        }
        @include mq(desktop) {
          font-size: 20px;
          letter-spacing: .2em;
        }
      }
      &:before {
        position: absolute;
        width: (24px/2);
        height: 1px;
        left: 0;
        top: (-22px/2);
        background-color: $white-color;
        @include opacity(.8);
        content: '';
        @include mq(tablet) {
          display: none;
        }
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    display: inline-block;
    width: (72px/2);
    height: (72px/2);
    top: 0;
    bottom: 0;
    margin: auto;
    vertical-align: middle;
    @include icon-style('ic-m-arrow-r-nor.svg', (22px/2), no-repeat, center center);
    transition: all 300ms;
    @include opacity(.6);
    &:hover {
      @include opacity(1);
    }
  }
  .swiper-button-prev {
    left: 10px;
    transform: scale(-1, 1);
  }
  .swiper-button-next {
    right: 10px;
  }
}