.part {
  text-align: left;
  &-section {
    display: none;
    @include mq(tablet) {
      display: block;
      padding-top: 60px;
    }
  }
  &-title {
    padding-bottom: (50px/2);
    border-bottom: 1px solid #e5e5e5;
    @include mq(desktop) {
      display: none;
    }
  }
  &-name {
    margin-top: (43px/2);
    font-size: (32px/2);
    font-weight: 500;
    color: $point-color;
    @include mq(desktop) {
      margin-top: 0;
      padding-top: 11px;
      padding-bottom: 20px;
      font-size: 24px;
      border-bottom: 1px solid #e5e5e5;
    }
  }
  &-desc {
    padding-top: (34px/2);
    font-size: (22px/2);
    font-weight: 400;
    color: $desc-color;
    line-height: (40/22);
    word-wrap: break-word;
    word-break: keep-all;
    @include mq(desktop) {
      padding-top: 20px;
      font-size: 12px;
      line-height: (22/12);
    }
  }
  &-cont {
    margin-top: (51px/2);
  }
  &-able {
    padding-top: (121px/2);
    text-align: right;
    font-size: (22px/2);
    line-height: (40/22);
    color: $red-color;
    @include mq(tablet) {
      display: none;
    }
    &__tab {
      position: absolute;
      display: inline-block;
      left: 50%;
      top: 50%;
      width: 100%;
      text-align: center;
      font-size: 12px;
      color: $red-color;
      transform: translate(-50%, -50%);
    }
  }
}