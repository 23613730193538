.result {
  &-section {
    font-size: 0;
    &:before {
      display: inline-block;
      width: 1px;
      height: 100%;
      margin-left: -1px;
      content: '';
      vertical-align: middle;
    }
  }
  &-wrapper {
    display: inline-block;
    width: 100%;
    text-align: center;
    vertical-align: middle;
  }
  &-icon {
    width: 54px;
    height: 56px;
    margin: 0 auto;
    @include icon-style('ic-recruit-complete.svg', 100% auto, no-repeat, center center);
  }
  &-cont {
    margin-top: 30px;
    color: rgba($white-color, .9);
    font-weight: 400;
  }
  &-title {
    font-size: 16px;

  }
  &-desc {
    margin-top: 24px;
    font-size: 12px;
    line-height: (20/12);
  }
}