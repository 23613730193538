.header {
  position: relative;
  .layout-wrapper {
    position: relative;
    height: 100%;
    font-size: 0;
    text-align: center;
  }
  &-top {
    position: fixed;
    height: $header-height;
    width: 100%;
    top: 0;
    z-index: 100;
    transform: translate3d(0, -100%, 0);
    transition: height 200ms, transform 300ms 200ms, background-color 0ms 500ms;
    @include mq(tablet) {
      height: $tablet-sub-header-height;
    }
    @include mq(desktop) {
      height: $desktop-sub-header-height;
    }
    &__active {
      transform: translate3d(0, 0, 0);
      box-shadow: 0 0 20px 0 rgba(0,0,0,.1);
      background-color: $white-color;
      transition: height 500ms, transform 500ms 100ms;
    }
    &__first {
      background-color: transparent;
      box-shadow: none;
      @include mq(tablet) {
        height: $tablet-header-height;
      }
      @include mq(desktop) {
        height: $desktop-header-height;
      }
      &.header-top__lock {
        background-color: transparent !important;
        box-shadow: none !important;
      }
    }
    &__lock {
      transform: translate3d(0,0,0) !important;
      background-color: $white-color !important;
      box-shadow: 0 0 20px 0 rgba(0,0,0,.1) !important;
      transition: 0ms !important;
    }
    &.freeze-hide {
      opacity: 0;
    }
  }
  &-logo {
    position: absolute;
    width: (194px/2);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    @include mq(tablet) {
      width: 84px;
      left: $tablet-padding;
      transform: translate(0, -50%);
    }
    @include mq(desktop) {
      width: 102px;
      left: $desktop-padding;
    }
    @include mq(wide) {
      left: $wide-padding;
    }
    &__anchor {
      display: block;
      width: 100%;
      height: 100%;
      padding: (26px/2) (24px/2) (20px/2);
      @include mq(tablet) {
        padding: 10px 7px;
      }
      @include mq(desktop) {
        padding: 13px 9px 12px;
      }
    }
  }
}
.menu {
  &-btn {
    position: absolute;
    width: (90px/2);
    height: (90px/2);
    top: 0;
    bottom: 0;
    left: (10px/2);
    margin: auto;
    font-size: 0;
    text-indent: -999px;
    overflow: hidden;
    @include icon-style('ic-btn-menu.svg', (38px/2) auto, no-repeat, (22px/2) center);
    @include mq(tablet) {
      display: none;
    }
  }
  &-bar {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #999;
    border-radius: 1px;
    &-top {
      position: absolute;
      top: 0;
    }
    &-middle {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 80%;
      margin: auto 0;
    }
    &-bottom {
      position: absolute;
      bottom: 0;
    }
  }
}

// dim
.page-dim {
  position: fixed;
  display: block;
  top: 0;
  bottom: 0;
  width: 200%;
  height: 100%;
  background-color: $title-color;
  z-index: 100;
  transform: translateX(-100%);
  @include opacity(.6);
  &--active {
    z-index: 105;
    transform: translateX(0);
    transition: all 500ms cubic-bezier(.45,.035,.045,.95);
  }
  @include mq(tablet) {
    display: none;
  }
}

// side
.side {
  position: fixed;
  display: block;
  left: -$mobile-menu-width;
  top: 0;
  bottom: 0;
  height: 100%;
  width: $mobile-menu-width;
  background-color: $white-color;
  z-index: 110;
  transform: translate3d(0,0,0);
  @include mq(tablet) {
    display: none;
  }
  &--active {
    transform: translate3d($mobile-menu-width,0,0);
    transition: transform 350ms cubic-bezier(.45,.035,.045,.95) 150ms;
  }
  &-wrap {
    padding: (30px/2);
  }
  &-list {
    padding: (36px/2) (15px/2) 0;
  }
  &-menu {
    font-family: $montserrat-font;
    font-size: (32px/2);
    font-weight: 700;
    color: $title-color;
    letter-spacing: .016em;
    @include opacity(.6);
    & ~ & {
      margin-top: (77px/2);
    }
    &.active {
      @include opacity(1);
    }
  }

  &-sns {
    &_list {
      margin-top: (103px/2);
      font-size: 0;
    }
    &_item {
      display: inline-block;
      width: (54px/2);
      height: (54px/2);
      & ~ & {
        margin-left: (24px/2);
      }
    }
    &_anchor {
      display: block;
      width: 100%;
      height: 100%;
      transition: all 300ms;
      //@include opacity(.5);
      &:hover {
        @include opacity(1);
      }
      &--facebook {
        @include icon-style('ic-facebook-g-act.svg', 7px 15.5px, no-repeat, center center);
      }
      &--blog {
        @include icon-style('ic-blog-g-act.svg', 17px 15.5px, no-repeat, center center);
      }
      &--share {
        @include  icon-style('ic-share-g-act.svg', 14px 14px, no-repeat, center center);
      }
      &--instagram {
        @include icon-style('ic-m-insta-g-act.svg', 15px 15px, no-repeat, center center);
      }
    }
  }
}

.gnb {
  display: none;
  @include mq(tablet) {
    position: absolute;
    display: block;
    left: auto;
    top: 50%;
    right: $tablet-padding;
    transform: translateY(-50%);
  }
  @include mq(desktop) {
    right: $desktop-padding;
  }
  @include mq(wide) {
    right: $wide-padding;
  }
  &-anchor {
    font-family: $montserrat-font;
    font-weight: 400;
    font-size: 12px;
    color: $black-color;
    letter-spacing: 0.012em;
    text-align: left;
    @include mq(tablet) {
      display: block;
      width: 75px;
      padding: 6px;
      @include opacity(.6);
    }
    @include mq(desktop) {
      width: 90px;
      padding: 15px;
    }
    &:hover {
      @include opacity(.8);
    }
    .active & {
      font-weight: 600;
      @include opacity(1);
    }
  }
  &-wrap {
    @include clearfix();
    > div {
      float: left;
      margin-top: 0;
    }
  }
  div ~ div {
    @include mq(tablet) {
      margin-top: 0;
      margin-left: 9px;
    }
    @include mq(desktop) {
      margin-left: 0;
    }
  }
}