@mixin icon-style($name, $size: '', $repeat: '', $position: '') {
  @if $name != '' {
    background-image: url('../img/icon/#{$name}');
  }
  @if $size != '' {
    background-size: $size;
  }
  @if $repeat != '' {
    background-repeat: $repeat;
  }
  @if $position != '' {
    background-position: $position;
  }
}