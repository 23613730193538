
@mixin grid($color) {
  background-image: repeating-linear-gradient(
      90deg,
      transparent,
      transparent 86px,
      $color 86px,
      $color 87px,
      transparent 87px,
      transparent 100%
  );
  background-size: (344px/2) auto;
  background-position: center 0;
  @include mq(tablet) {
    background-image: repeating-linear-gradient(
        90deg,
        transparent,
        transparent 85px,
        $color 85px,
        $color 86px,
        transparent 86px,
        transparent 100%
    );
    background-size: 168.5px auto;
  }
  @include mq(desktop) {
    background-image: repeating-linear-gradient(
        90deg,
        transparent,
        transparent 50%,
        $color 50%,
        $color calc(50% + 2px),
        transparent calc(50% + 2px),
        transparent 100%
    );
    background-size: percentage(306/1440) auto;
  }
  @include mq(wide) {
    background-image: repeating-linear-gradient(
        90deg,
        transparent,
        transparent 50%,
        $color 50%,
        $color calc(50% + 2px),
        transparent calc(50% + 2px),
        transparent 100%
    );
    background-size: percentage(360/1920) auto;
  }
  @include mq(full) {
    background-size: 360px auto;
  }
}