
// device width
$mobile-width: 320px;
$tablet-width: 768px;
$desktop-min-width: 1025px;
$desktop-width: 1200px;
$wide-width: 1441px;
$full-width: 1920px;

$responsive-type: (
  'mobile': '(min-width: #{$mobile-width})',
  'only-mobile': '(min-width: #{$mobile-width}) and (max-width: #{$tablet-width - 1px})',
  'mobile-landscape': '(min-width: 480px) and (max-width: 812px) and (orientation: landscape)',
  'tablet': '(min-width: #{$tablet-width})',
  'tablet-landscape': '(min-width: 1024px) and (max-width: #{$desktop-width - 1px}) and (orientation: landscape)',
  'only-tablet': '(min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px})',
  'desktop': '(min-width: #{$desktop-width})',
  'wide': '(min-width: #{$wide-width})',
  'full': '(min-width: #{$full-width})',
);

@mixin mq($type) {
  $media-query: map-get($responsive-type, $type);
  @if ($media-query) {
    @media all and #{$media-query} {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin retina () {
  @media screen and (min-resolution: 2dppx) {
    @content;
  }
}

@mixin landscape() {
  @media screen and (orientation: landscape) {
    @content;
  }
}