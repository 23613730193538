// form
.form {
  &-group {
    margin-bottom: 0;
    & ~ & {
      margin-top: (38px/2);
      @include mq(tablet) {
        margin-top: 21px;
      }
    }
    &-part {
      & ~ & {
        margin-top: 25px;
      }
    }
  }

  &-item {
    position: relative;
    font-size: 0;
    &__border {
      border-bottom: 1px solid $white-color;
      &.error {
        border-bottom: 1px solid $red-color;
      }
    }
    &__textarea {
      padding-bottom: 10px;
      border-bottom: 1px solid $white-color;
      @include mq(tablet) {
        padding-bottom: 20px;
      }
    }
    &.error .form-label {
      color: $red-color;
    }
    &.error .form-box {
      border: 1px solid rgba($red-color,.5);
    }
  }

  &-input {
    display: inline-block;
    width: 100%;
    padding: (5px/2) (16px/2);
    font-size: (26px/2);
    line-height: 2.14;
    color: $white-color;
    caret-color: $point-color;
    @include mq(tablet) {
      padding: 7px 10px;
      font-size: 12px;
      line-height: (24/12);
    }
    @include mq(desktop) {
      font-size: 14px;
      line-height: (30/14);
    }
  }

  &-label {
    position: absolute;
    left: 0;
    top: 0;
    padding: (5px/2) (16px/2);
    font-size: (26px/2);
    color: $white-color;
    line-height: 2.14;
    pointer-events: none;
    transition: all 300ms;
    @include opacity(.6);
    @include mq(tablet) {
      padding: 7px 10px;
      font-size: 12px;
      line-height: (24/12);
    }
    @include mq(desktop) {
      font-size: 14px;
      line-height: (30/14);
    }
    .form-item__focus & {
      font-size: (20px/2);
      transform: translateY((-25px/2));
    }
    .form-item__value & {
      font-size: (20px/2);
      transform: translateY((-25px/2));
    }
  }

  &-box {
    display: inline-block;
    width: 100%;
    caret-color: $point-color;
    background-color: rgba(0,0,0,.2);
    @include mq(tablet) {
      padding-left: 21px;
      padding-right: 21px;
      font-size: 12px;
      line-height: 40px;
      height: 42px;
      color: $point-bright;
      border: 1px solid transparent;
    }
    &-file {
      @include mq(tablet) {
        width: 70%;
        white-space: nowrap;
        vertical-align: top;
        overflow: auto;
      }
      @include mq(desktop) {
        width: 244px;
      }
    }
  }

  &-holder {
    display: none;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    @include mq(tablet) {
      display: block;
      padding-left: 21px;
      padding-right: 21px;
      font-size: 12px;
      font-weight: 500;
      line-height: 42px;
      color: $point-color;
      @include opacity(.25);
    }
    &__file {
      @include mq(tablet) {
        width: 70%;
        white-space: nowrap;
        overflow: auto;
      }
      @include mq(desktop) {
        width: 244px;
      }
    }
  }

  &-title {
    margin-bottom: 10px;
    padding-left: 5px;
    font-size: 12px;
    color: rgba($white-color,.6);
    .error {
      color: rgba($point-bright,.6);
    }
  }
  &-textarea {
    resize: none;
    height: auto;
  }
  &-checkbox {
    position: relative;
    margin-top: (44px/2);
    text-align: right;
    &__left {
      text-align: left;
    }
    &.error {
      .form-error {
        display: block;
      }
    }
  }

  &-error {
    display: none;
    position: absolute;
    right: 0;
    bottom: -19px;
    font-size: (22px/2);
    font-weight: 500;
    color: rgba($red-bright,.9);
    @include mq(tablet) {
      font-size: 12px;
      color: $red-color;
    }
    &__popup {
      @include mq(tablet) {
        left: 0;
        right: auto;
        color: rgba($red-bright,.6);
      }
    }
    &__title {
      font-size: 12px;
      font-weight: 500;
      color: rgba($red-bright, .6);
    }
  }

}
.required-unit {
  color: rgba($point-bright, .6);
}

input[type='file'] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}
:focus {
  outline: transparent;
}
::selection {
  background-color: $point-bright;
  color: $white-color;
  text-shadow: none;
}
::-moz-selection {
  background-color: $point-bright;
  color: $white-color;
  text-shadow: none;
}
::-webkit-selection {
  background-color: $point-bright;
  color: $white-color;
  text-shadow: none;
}