.section {
  position: relative;
  width: 100%;
  height: 100%;
  &-description {
    display: inline-block;
    word-wrap: break-word;
    word-break: keep-all;
  }
  &-name {
    position: relative;
    display: inline-block;
    font-size: 0;
    &:before {
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      right: 0;
      bottom: -2px;
      background-color: $point-color;
      @include opacity(.5);
      content: '';
    }
    &_img {
      @include opacity(.8);
    }
  }
  &-title {
    margin-top: 15px;
    font-size: (46px/2);
    font-weight: 500;
    line-height: (62/46);
    color: $title-color;
    @include mq(tablet) {
      margin-top: 20px;
      font-size: 28px;
      line-height: (40/28);
    }
    @include mq(desktop) {
      font-size: 36px;
      line-height: (52/36);
    }
    @include mq(wide) {
      margin-top: 50px;
    }
    .home & {
      margin-top: 13px;
      font-size: (56px/2);
      line-height: (72/56);
      @include mq(tablet) {
        margin-top: 14px;
        font-size: 32px;
        line-height: (46/32);
      }
      @include mq(desktop) {
        margin-top: 16px;
        font-size: 42px;
        line-height: (60/42);
      }
      @include mq(wide) {
        margin-top: 50px;
      }
    }
    .contact & {
      color: $white-color;
    }
    & b {
      font-weight: 600;
    }
  }
  &-subtitle {
    margin-top: (70px/2);
    font-size: (32px/2);
    font-weight: 700;
    line-height: 1;
    color: $point-color;
    text-align: left;
    @include mq(tablet) {
      margin-top: 0;
      font-size: 18px;
    }
    @include mq(desktop) {
      font-size: 21px;
    }
  }
  &-desc {
    margin-top: 18px;
    font-size: (26px/2);
    font-weight: 400;
    line-height: (44/26);
    color: #444444;
    @include mq(tablet) {
      margin-top: 25px;
      font-size: 14px;
      line-height: (24/14);
    }
    @include mq(desktop) {
      margin-top: 26px;
      font-size: 16px;
      line-height: (26/16);
    }
    & ~ &__sub {
      margin-top: 15px;
      font-size: (24px/2);
      line-height: 1;
      color: #444444;
      @include mq(tablet) {
        margin-top: 25px;
        font-size: 14px;
      }
      @include mq(desktop) {
        margin-top: 21px;
        font-size: 15px;
      }
    }
    & em {
      color: $black-color;
    }
    & b {
      font-weight: 700;
    }
    .home & {
      @include mq(tablet) {
        margin-top: 20px;
      }
      @include mq(desktop) {
        margin-top: 27px;
      }
    }
    .recruit & {
      margin-top: 16px;
      text-align: left;
      @include mq(tablet) {
        margin-top: 18px;
      }
      @include mq(desktop) {
        margin-top: 24px;
        font-size: 18px;
      }
    }
  }
}
.fp-scroller {
  height: 100%;
}
.contact {
  .fp-scroller {
    height: auto;
    @include mq(tablet) {
      height: 100%;
    }
  }
}
