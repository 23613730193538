.mobile {
  &-wrapper {
    position: relative;
    padding-top: (242px/2);
    padding-bottom: (162px/2);
    font-size: 0;
    text-align: left;
    @include clearfix();
    @include mq(tablet) {
      padding-top: 185px;
      padding-bottom: 115px;
    }
    @include mq(desktop) {
      padding-top: 209px;
      padding-bottom: 127px;
    }
    @include mq(wide) {
      padding-top: 214px;
      padding-bottom: 143px;
    }
  }
  &-name {
    width: (119px/2);
    height: 10px;
    background: url('../img/logo/img-text-mobileapp.svg') no-repeat center center;
    background-size: 100% auto;
    @include mq(tablet) {
      width: 72px;
      height: 6px;
    }
    @include mq(desktop) {
      width: 89px;
      height: 7px;
    }
  }
  &-title_line {
    & ~ & {
      margin-left: (73px/2);
      @include mq(tablet) {
        margin-left: 57px;
      }
      @include mq(desktop) {
        margin-left: 71px;
      }
    }
  }
  &-description {
    position: absolute;
    width: auto;
    margin: auto;
    top: (170px/2);
    left: percentage(365 / 750);
    @include mq(tablet) {
      top: 140px;
      left: percentage(462/768);
    }
    @include mq(desktop) {
      top: 205px;
      left: percentage(826/1440);
    }
  }

  &-app {
    width: (1068px/2);
    margin-left: (-290px/2);
    @include clearfix();
    @include mq(tablet) {
      width: 100%;
      margin-left: 0;
    }
    &__img {
      @include mq(tablet) {
        width: 642px;
        margin-left: 27px;
      }
      @include mq(desktop) {
        float: right;
        width: percentage(875/1440);
        max-width: 875px;
        margin-right: percentage(413/1440);
      }
      @include mq(wide) {
        width: percentage(1029/1920);
        max-width: 1029px;
        margin-right: (619/1920);
      }
    }
  }

  &-button {
    margin-top: (70px/2);
    @include mq(tablet) {
      margin-top: 61px;
    }
    @include mq(desktop) {
      margin-top: 73px;
    }
    &__desc {
      font-size: (24px/2);
      font-weight: 500;
      color: #999999;
      text-align: center;
      @include mq(tablet) {
        font-size: 14px;
      }
      @include mq(desktop) {
        font-size: 16px;
      }
    }
    &__wrap {
      margin-top: (40px/2);
      text-align: center;
      font-size: 0;
      @include mq(tablet) {
        margin-top: 31px;
      }
    }
    &__app {
      display: inline-block;
      width: (333.97px/2);
      height: (88.08px/2);
      vertical-align: middle;
      font-size: 0;
      @include opacity(.9);
      @include mq(tablet) {
        width: 182px;
        height: 48px;
      }
      &:hover {
        @include opacity(1);
      }
      &.btn-ios {
        @include icon-style('ic-down-ios.png', 100% auto, no-repeat, center center);
      }
      &.btn-android {
        @include icon-style('ic-down-android.png', 100% auto, no-repeat, center center);
      }
      & img {
        transform: translate3d(0, 0, 0);
      }
      & ~ & {
        margin-left: (22px/2);
        @include mq(tablet) {
          margin-left: 14px;
        }
      }
    }
  }
}
