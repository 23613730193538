.footer {
  position: relative;
  width: 100%;
  height: auto;
  text-align: center;
  &-spacer {
    height: $footer-height;
    @include mq(tablet) {
      height: $tablet-footer-height;
    }
    @include mq(desktop) {
      height: $desktop-footer-height;
    }
  }
}
.copyright {
  position: absolute;
  width: 100%;
  top: 0;
  font-family: $montserrat-font;
  font-weight: 400;
  font-size: (22px/2);
  letter-spacing: 0.01em;
  line-height: $footer-height;
  color: $white-color;
  background-color: #111418;
  z-index: 10;
  @include mq(tablet) {
    font-size: 10px;
    line-height: $tablet-footer-height;
  }
  @include mq(desktop) {
    font-size: 12px;
    line-height: $desktop-footer-height;
  }
}