.employ {
  text-align: left;
  &-section {
    @include mq(tablet) {
      padding-top: 96px;
    }
  }
  &-cont {
    margin-top: (45px/2);
    @include mq(tablet) {
      margin-top: 37px;
    }
    &__row {
      font-size: 0;
    }
    &__term,
    &__desc {
      display: inline-block;
      padding-top: (12px/2);
      padding-bottom: (30px/2);
      vertical-align: top;
      font-size: (21px/2);
      font-weight: normal;
      @include mq(tablet) {
        line-height: (20/12);
        padding-top: 3px;
        padding-bottom: 23px;
        font-size: 12px;
      }
    }
    &__term {
      width: 19.7%;
      margin-right: 6.7%;
      color: $point-color;
      border-top: 1px solid $point-bright;
      @include mq(tablet) {
        margin-right: 3.6%;
      }
      @include mq(desktop) {
        margin-right: 4.6%;
      }
    }
    &__desc {
      width: 73.6%;
      color: $black-color;
      border-top: 1px solid #e5e5e5;
      @include mq(tablet) {
        width: 76.7%;
      }
      @include mq(desktop) {
        width: 75.7%;
      }
    }
  }

  &-part {
    &__item {
      font-size: 0;
      & ~ & {
        margin-top: (52px/2);
        @include mq(tablet) {
          margin-top: 0;
        }
      }
    }
    &__anchor {
      font-size: (36px/2);
      font-weight: 500;
      @include mq(tablet) {
        height: 56px;
        line-height: 56px;
        font-size: 24px;
        font-weight: 600;
      }
    }
    &__name {
      display: inline-block;
      color: $white-color;
      @include opacity(.7);
    }
    &__icon {
      display: inline-block;
      margin-left: (16px/2);
      width: (29px/2);
      height: (29px/2);
      @include icon-style('ic-plus-blue-on.svg', 100% auto, no-repeat, center center);
      @include opacity(.6);
      @include mq(tablet) {
        margin-left: 18px;
        width: 20px;
        height: 20px;
      }
    }
    &__anchor:hover {
      .employ-part__name {
        @include opacity(1);
      }
      .employ-part__icon {
        @include opacity(1);
      }
    }
  }
}