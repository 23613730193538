.dialog {
  &-wrap {
    &:before {
      background-color: $point-color;
      @include mq(tablet) {
        left: -6px;
        top: -6px;
        width: 70px;
        height: 70px;
      }
    }
  }
  &-close {
    @include opacity(.5);
    @include mq(tablet) {
      top: 8px;
      right: 8px;
      @include icon-style('ic-btn-close.svg');
    }
    &:hover {
      @include opacity(.8);
    }
  }
}

.alert {
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  &-section {
    position: relative;
    width: (614px/2);
    height: (422px/2);
    @include mq(desktop) {
      width: 320px;
      height: 220px;
    }
    &:before {
      display: inline-block;
      width: 1px;
      height: 100%;
      margin-left: -1px;
      vertical-align: middle;
      content: '';
    }
  }
  &-msg {
    display: inline-block;
    margin-top: -50px;
    font-size: (28px/2);
    font-weight: 400;
    line-height: (22/14);
    color: $desc-color;
    vertical-align: middle;
    @include mq(tablet) {
      font-size: 14px;
    }
  }
  &-btn {
    position: absolute;
    left: 0;
    right: 0;
    bottom: (74px/2);
    @include mq(tablet) {
      bottom: 34px;
    }
  }
}