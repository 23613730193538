.home {
  width: 100%;
  height: 100%;
  text-align: left;
  &-wrapper {
    padding-top: $header-height;
    position: relative;
    text-align: right;
    font-size: 0;
    @include mq(tablet) {
      padding-top: $tablet-header-height;
    }
    @include mq(desktop) {
      padding-top: $desktop-header-height;
    }
  }
  &-name {
    width: (111px/2);
    height: (12px/2);
    background: url('../img/logo/img-text-quriously.svg') no-repeat center center;
    background-size: 100% auto;
    @include mq(tablet) {
      width: 67px;
      height: 7px;
    }
    @include mq(desktop) {
      width: 84px;
      height: 9px;
    }
  }
  &-description {
    position: absolute;
    width: (540px/2);
    left: (32px/2);
    top: (287px/2);
    text-align: left;
    z-index: 10;
    @include mq(tablet) {
      top: 50%;
      left: $tablet-padding;
      width: 377px;
      transform: translateY(-50%);
    }
    @include mq(desktop) {
      left: $desktop-padding;
      width: 435px;
    }
    @include mq(wide) {
      left: $wide-padding;
    }
  }
}
.home {
  .swiper-container {
    position: relative;
    display: inline-block;
    width: (542px/2);
    margin: auto;
    font-size: 0;
    box-shadow: (-15.9px/2) (25.4px/2) (65px/2) 0 rgba(11,12,17,.2);
    vertical-align: middle;
    overflow: visible;
    @include mq(tablet) {
      width: percentage(560/768);
    }
    @include mq(desktop) {
      width: percentage(1016/1440);
      max-width: 1016px;
      margin-right: $desktop-padding;
    }
    @include mq(wide) {
      width: percentage(1270/1920);
      max-width: 1270px;
      margin-right: $wide-padding;
    }
  }
  &-slide {
    transition: 0ms;
    transition-delay: 0ms;
    @include opacity(0);
    &.swiper-slide-active {
      @include opacity(1);
    }
    &-img {
      img {
        width: auto;
        max-width: none;
        height: 100%;
        max-height: (924px/2);
        @include mq(tablet) {
          width: 100%;
          max-width: 100%;
          height: auto;
          max-height: none;
        }
      }
    }
    &__overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $point-color;
      z-index: 100;
      transform: scale(0,1);
      &.next {
        transform-origin: 0 50%;
        -ms-transform-origin: 0 50%;
        &.next-end {
          transform-origin: 100% 50%;
          -ms-transform-origin: 100% 50%;
        }
      }
      &.prev {
        transform-origin: 100% 50%;
        -ms-transform-origin: 100% 50%;

        &.prev-end {
          transform-origin: 0 50%;
          -ms-transform-origin: 0 50%;
        }
      }
      &.overlay-animate {
        transform: scale(1,1);
      }
    }
  }
  &-slide-controller {
    position: absolute;
    bottom: (-11px/2);
    left: percentage(-196/542);
    font-size: 0;
    z-index: 50;
    @include mq(tablet) {
      left: percentage(-168.5/560);
      bottom: 10px;
    }
    @include mq(desktop) {
      left: percentage(-215.5/1016);
    }
    @include mq(wide) {
      left: percentage(-177/1270);
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    position: static;
    display: inline-block;
    width: (56px/2);
    height: (56px/2);
    margin-top: auto;
    vertical-align: middle;
    transition: all 300ms;
    @include icon-style('ic-m-arrow-r-nor.svg', (18px/2) auto, no-repeat, center center);
    @include opacity(.6);
    @include mq(tablet) {
      background-size: 11px auto;
    }
    &:hover {
      @include opacity(1);
    }
  }
  .swiper-button-prev {
    transform: scale(-1, 1);
  }
  .swiper-pagination {
    &-fraction {
      position: static;
      display: inline-block;
      width: (67px/2);
      color: #ebebeb;
      font-family: $montserrat-font;
      font-size: 11px;
      font-weight: 600;
      letter-spacing: 0.044em;
      vertical-align: middle;
      text-align: center;
      @include mq(tablet) {
        font-size: 12px;
      }
    }
    &-current {
      color: $point-color;
    }
    &-total {
      color: $desc-color;
    }
  }
  &-sns {
    display: none;
    @include mq(desktop) {
      position: absolute;
      display: block;
      right: -72px;
      bottom: 0;
    }
    &_item {
      width: 34px;
      height: 34px;
      & ~ & {
        margin-top: 17px;
      }
    }
    &_anchor {
      display: block;
      width: 100%;
      height: 100%;
      text-indent: -999px;
      font-size: 0;
      transition: all 300ms;
      overflow: hidden;
      @include opacity(.4);
      &:hover {
        @include opacity(.8);
      }
      &--facebook {
        @include icon-style('ic-facebook-g-act.svg', 8px auto, no-repeat, center center);
      }
      &--blog {
        @include icon-style('ic-blog-g-act.svg', 20px auto, no-repeat, center center);
      }
      &--share {
        @include  icon-style('ic-share-g-act.svg', 17px auto , no-repeat, center center);
      }
      &--instagram {
        @include icon-style('ic-insta-g-act.svg', 18px auto, no-repeat, center center);
      }
    }
  }
}