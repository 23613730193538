.modal {
  font-size: 0;
  text-align: center;
  &:before {
    display: inline-block;
    width: 1px;
    height: 100%;
    margin-left: -1px;
    vertical-align: middle;
    content: '';
  }
  &-dialog {
    display: inline-block;
    width: auto;
    margin: 0 auto;
    text-align: center;
    vertical-align: middle;
  }
  &-content {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    box-shadow: none;
  }

  &-wrap {
    position: relative;
    display: inline-block;
    font-size: 0;
    &:before {
      position: absolute;
      display: block;
      left: (-12px/2);
      top: (-12px/2);
      width: (134px/2);
      height: (134px/2);
      background-color: #272a2e;
      content: '';
      z-index: 5;
      @include mq(tablet) {
        left: -12px;
        top: -12px;
        width: 128px;
        height: 128px;
      }
    }
  }

  &-container {
    position: relative;
    z-index: 10;
    @include clearfix();
  }
  &-bg {
    background-color: $white-color;
  }

  &-section {
    position: relative;
    width: (614px/2);
    padding: (38px/2);
    background-color: $white-color;
    @include mq(tablet) {
      width: 360px;
      height: 528px;
      padding: 36px;
      float: left;
    }
    @include mq(desktop) {
      width: 376px;
      height: 528px;
    }
    &__black {
      padding: (50px/2) (38px/2);
      background-color: #272a2e;
      @include mq(tablet) {
        width: 360px;
        padding: 36px;
      }
      @include mq(desktop) {
        width: 416px;
        padding: 36px;
      }
    }
  }
  &-title {
    padding-top: (20px/2);
    font-size: (42px/2);
    font-weight: 600;
    line-height: 1;
    color: $black-color;
    @include mq(desktop) {
      padding-top: 11px;
      font-size: 24px;
    }
  }

  &-close {
    position: absolute;
    top: (12px/2);
    right: (12px/2);
    width: (60px/2);
    height: (60px/2);
    @include icon-style('ic-btn-close.svg', (24px/2) auto, no-repeat, center center);
    z-index: 1;
    @include opacity(.8);
    @include mq(tablet) {
      top: 15px;
      right: 24px;
      width: 36px;
      height: 36px;
      @include icon-style('ic-btn-close-w.svg', 12px auto);
      @include opacity(.5);
    }
    &:hover {
      @include opacity(.5);
      @include mq(desktop) {
        @include opacity(.8);
      }
    }
  }

  &-logo {
    position: absolute;
    right: (38px/2);
    bottom: (39px/2);
    width: (95px/2);
    height: (29px/2);
    background-image: url('../img/logo/img-logo-sub.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
    @include mq(tablet) {
      right: 38px;
      bottom: 31px;
      width: 57px;
      height: 17px;

    }
  }

  &-btn {
    &-mob {
      width: 100%;
      height: (108px/2);
      background-color: #272a2e;
      @include mq(tablet) {
        display: none;
      }
    }
    &-back {
      display: block;
      width: 100%;
      height: 100%;
      font-size: 0;
      text-align: center;
    }
    &-icon {
      display: inline-block;
      margin-top: -1px;
      width: (23px/2);
      height: (19px/2);
      @include icon-style('ic-arrow-04.svg', 100% auto, no-repeat, center center);
      vertical-align: middle;
    }
    &-text {
      display: inline-block;
      margin-left: (18px/2);
      font-size: (24px/2);
      color: $white-color;
      vertical-align: middle;
    }
    &-left,
    &-right {
      display: none;
      @include mq(tablet) {
        position: absolute;
        display: block;
        bottom: 30px;
      }
    }
    &-left {
      left: 36px;
    }
    &-right {
      right: 36px;
    }
  }
}

// modal animation
.modal {
  &.fade .modal-dialog {
    transition: transform 300ms cubic-bezier(0.17, 0.02, 0.0, 0.33);
    transform: translate(0, -50px);
  }
  &.in .modal-dialog {
    transform: translate(0, 0);
  }
  &-backdrop {
    background-color: #000;
    transition: all 200ms;
    &.in {
      @include opacity(.5);
    }
  }
}