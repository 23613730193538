// checkbox
.checkbox {
  position: relative;
  font-size: 0;
  vertical-align: middle;

  &-input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    @include opacity(0);
    &:checked ~ .checkbox-label {
      .checkbox-label__text {
        @include opacity(1);
      }
    }
    &:checked ~ .checkbox-label:before {
      @include icon-style('check-box-on.svg');
      @include opacity(1);
    }
  }

  &-label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    color: rgba($white-color, .7);
    vertical-align: middle;
    &:before {
      display: inline-block;
      width: (20px/2);
      height: (20px/2);
      vertical-align: middle;
      content: '';
      @include icon-style('check-box-off.svg', 10px auto, no-repeat, center center);
      @include mq(tablet) {
        width: 12px;
        height: 12px;
        background-size: auto 100%;
      }
    }
    &__text {
      display: inline-block;
      margin-left: (9px/2);
      vertical-align: middle;
      font-size: 0;
      line-height: 1.1;
      @include mq(tablet) {
        margin-left: 5px;
      }
    }
    &__anchor {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      font-size: (22px/2);
      @include mq(tablet) {
        font-size: 10px;
      }
      @include mq(desktop) {
        font-size: 12px;
      }
      &:before {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -1px;
        width: 100%;
        border-bottom: 1px solid rgba($white-color, .7);
        content: '';
      }
    }
    &__subtxt {
      display: inline-block;
      vertical-align: middle;
      font-size: (22px/2);
      @include mq(tablet) {
        font-size: 10px;
      }
      @include mq(desktop) {
        font-size: 12px;
      }
    }
    // hover
    &:hover {
      &:before {
        @include icon-style('check-box-on.svg');
        @include opacity(.502);
      }
    }
  }
}