.contact {
  position: relative;
  &-wrapper {
    position: relative;
    width: 100%;
    font-size: 0;
    text-align: center;
    background-image: url('../img/bg-contact-mob.png');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% auto;
    @include mq(tablet) {
      background-image: url('../img/bg-contact-tablet.png');
      background-size: cover;
      background-position: center top;
      text-align: left;
    }
    @include mq(desktop) {
      background-image: url('../img/bg-contact-pc.png');
    }
    &:before,
    &:after {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      background-color: #272a2e;
      content: '';
      z-index: 1;
      @include opacity(.95);
      @include mq(tablet) {
        top: 0;
        width: percentage(337/768);
        height: 100%;
      }
      @include mq(desktop) {
        width: percentage(960/1920);
      }
    }
    &:after {
      left: auto;
      right: 0;
      top: (34px/2);
      bottom: 0;
      height: auto;
      @include mq(tablet) {
        display: none;
      }
    }
  }
  &-name {
    width: (87px/2);
    height: 10px;
    background: url('../img/logo/img-text-contact.svg') no-repeat center center;
    background-size: 100% auto;
    @include mq(tablet) {
      width: 52px;
      height: 6px;
    }
    @include mq(desktop) {
      width: 66px;
      height: 7px;
    }
  }
  &-info {
    position: relative;
    padding: (144px/2) (32px/2) 0;
    z-index: 10;
    @include mq(tablet) {
      display: inline-block;
      padding: 76px 0 0 $tablet-padding;
      width: percentage(337/768);
      vertical-align: top;
    }
    @include mq(desktop) {
      width: percentage(960/1920);
      //padding: 123px 0 0 percentage(215/1440);
      padding: percentage(155/1440) 0 0 percentage(215/1440);
    }
    @include mq(wide) {
      width: percentage(960/1920);
      padding: percentage(147/1920) 0 0 $wide-padding;
    }
  }
  &-description {
    display: block;
    text-align: left;
  }
  &-desc {
    margin-top: 0;
    padding-left: 50%;
    padding-top: (96px/2);
    @include mq(tablet) {
      padding-top: 124px;
      padding-left: 0;
    }
    @include mq(desktop) {
      padding-left: percentage(201/505);
    }
    @include mq(wide) {
      padding-left: percentage(372/720);
      padding-top: percentage(174/720);
    }
  }
  &-addr {
    font-size: (26px/2);
    font-weight: 400;
    line-height: (44/26);
    color: $white-color;
    @include mq(tablet) {
      font-size: 13px;
      line-height: (20/13);
    }
    @include mq(desktop) {
      font-size: 17px;
      line-height: (32/17);
    }
    &-title {
      font-weight: 700;
      line-height: 1;
      color: $point-color;
    }
    &-local {
      margin-top: (44px/2);
      @include mq(tablet) {
        margin-top: 28px;
      }
      @include mq(desktop) {
        margin-top: 24px;
        line-height: (26/17);
      }
    }
    &-line {
      margin-top: 4px;
      @include mq(tablet) {
        margin-top: 11px;
      }
      @include mq(desktop) {
        margin-top: 16px;
      }
    }
    &-mail {
      position: relative;
      display: inline-block;
      height: 100%;
      &:before {
        position: absolute;
        display: block;
        left: 0;
        right: 0;
        bottom: 2px;
        width: 100%;
        height: 1px;
        background-color: $white-color;
        content: '';
      }
    }
  }
  &-sns {
    margin-top: (21px/2);
    margin-left: (-20px/2);
    @include mq(tablet) {
      margin-left: (-25px/2);
      margin-top: 15px;
    }
    &_list {
      font-size: 0;
    }
    &_item {
      display: inline-block;
      width: (54px/2);
      height: (54px/2);
      vertical-align: middle;
      margin-left: 5.5px;
      @include mq(tablet) {
        width: 34px;
        height: 34px;
      }
      & ~ & {
        margin-left: (24px/2);
        @include mq(tablet) {
          margin-left: 5px;
        }
        @include mq(desktop) {
          margin-left: 1px;
        }
      }
    }
    &_anchor {
      display: block;
      width: 100%;
      height: 100%;
      text-indent: -999px;
      font-size: 0;
      transition: all 300ms;
      overflow: hidden;
      &:hover {
        @include opacity(1);
      }
      &--facebook {
        @include icon-style('ic-facebook-w-act.svg', 7px auto, no-repeat, center center);
        @include mq(tablet) {
          background-size: 9px auto;
          @include opacity(.75);
        }
      }
      &--blog {
        @include icon-style('ic-blog-w-act.svg', 17px auto, no-repeat, center center);
        @include mq(tablet) {
          background-size: 20px auto;
          @include opacity(.75);
        }
      }
      &--share {
        @include  icon-style('ic-share-w-act.svg', 14px auto, no-repeat, center center);
        @include mq(tablet) {
          background-size: 17px auto;
          @include opacity(.75);
        }
      }
      &--instagram {
        @include icon-style('ic-insta-w-act.svg', 15px auto, no-repeat, center center);
        @include mq(tablet) {
          background-size: 18px auto;
          @include opacity(.7);
        }
      }
    }
  }
  &-form {
    position: relative;
    padding: (155px/2) (30px/2) (114px/2);
    z-index: 10;
    @include mq(tablet) {
      display: inline-block;
      width: percentage(431/768);
      padding: 132px $tablet-padding 54px;
      vertical-align: top;
    }
    @include mq(desktop) {
      width: percentage(960/1920);
      padding: percentage(211/1440) $desktop-padding percentage(84/1440);
    }
    @include mq(wide) {
      width: percentage(960/1920);
      padding: percentage(244/1920) $wide-padding percentage(109/1920) percentage(200/1920);
    }
    &-btn {
      margin-top: (80px/2);
      font-size: 0;
      text-align: right;
      @include mq(tablet) {
        margin-top: 44px;
      }
      @include mq(desktop) {
        margin-top: 48px;
      }
    }
  }
  &-bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 5;
    pointer-events: none;
    @include grid(rgba(217, 217, 217, .1));
  }
}


