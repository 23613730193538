// color

$white-color: #ffffff;
$title-color: #222222;
$desc-color: #666666;
$point-color: #608be4;
$point-bright: #7ea7fd;
$gray-color: #dddddd;
$black-color: #333333;
$grid-color: #d9d9d9;
$red-color: #b82521;
$red-bright: #FF2B49;
$email-color: #55acee;
$fb-color: #3b5998;
$twitter-color: #55acee;

// Font
//@import url('http://fonts.googleapis.com/earlyaccess/notosanskr.css');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600');
$default-font: 'Apple SD Gothic Neo', 'AppleGothic', 'Noto sans KR', sans-serif;
$montserrat-font: 'Montserrat', sans-serif;

// layout
$layout-width: 1440px;
$layout-wide-width: 1920px;

// mobile menu width
$mobile-menu-width: (500px/2);

// header height
$header-height: (112px/2);
$tablet-header-height: 103px;
$tablet-sub-header-height: 60px;
$desktop-header-height: 127px;
$desktop-sub-header-height: 76px;

// footer height
$footer-height: (148px/2);
$tablet-footer-height: 78px;
$desktop-footer-height: 98px;

// global padding
$tablet-padding: 48px;
$desktop-padding: percentage(108/1440);
$wide-padding: percentage(240/1920);