[data-type] {
  opacity: 1;
  transform: translate(0, 0);
  &.show-up {
    transform: translate(0, 50px);
    transition: 0ms !important;
    .home & {
      opacity: 0;
    }
  }
  &.show-down {
    transform: translate(0, 50px);
    transition: 0ms !important;
    .home & {
      opacity: 0;
    }
  }
  &.hide-up {
    transform: translate(0, 50px);
    .home & {
      opacity: 0;
    }
  }
  &.hide-down {
    transform: translate(0, 50px);
    .home & {
      opacity: 0;
    }
  }
}
