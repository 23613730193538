.policy {
  &-section {
    position: relative;
    width: (614px/2);
    height: (889px/2);
    padding: (66px/2) (38px/2) (38px/2);
    @include mq(desktop) {
      width: 792px;
      height: 528px;
      padding: 47px 36px 0;
    }
  }
  &-title {
    padding-bottom: (27px/2);
    font-size: (32px/2);
    font-weight: 600;
    color: $point-color;
    text-align: left;
    border-bottom: 1px solid #e5e5e5;
    @include mq(desktop) {
      padding-bottom: 20px;
      font-size: 24px;
    }
  }
  &-wrapper {
    font-size: (24px/2);
    text-align: left;
    overflow: hidden;
    @include mq(desktop) {
      height: 402px;
      max-height: 402px;
      padding: 5px;
      font-size: 14px;
      background-color: #f7f7f7;
    }
    &__popup {
      display: none;
      @include mq(desktop) {
        display: block;
        height: 342px;
        max-height: 342px;
      }
    }
  }
  &-content {
    padding-top: (36px/2);
    color: $desc-color;
    word-wrap: break-word;
    word-break: keep-all;
    @include mq(desktop) {
      height: 100%;
      padding: 20px 12px 20px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  &-btn {
    display: none;
    @include clearfix();
    @include mq(desktop) {
      display: block;
      padding-top: 24px;
    }
    .btn-arrow-left {
      float: left;
    }
    .btn-arrow-right {
      float: right;
    }
  }
}

// contact privacy policy
.p {
  &-desc {
    padding-bottom: (40px/2);
    font-size: (24px/2);
    font-weight: 500;
    line-height: (32/24);
    @include mq(desktop) {
      padding-bottom: 30px;
      font-size: 14px;
      line-height: (22/14);
    }
  }

  &-1 {
    font-size: (22px/2);
    @include mq(desktop) {
      font-size: 12px;
    }
    & ~ & {
      padding-top: (32px/2);
      @include mq(desktop) {
        padding-top: 23px;
      }
    }
    &_tit {
      padding-bottom: (16px/2);
      font-size: (24px/2);
      font-weight: 500;
      line-height: 1;
      color: $point-color;
      @include mq(desktop) {
        padding-bottom: 6px;
        font-size: 14px;
      }
    }
    &_desc {
      line-height: (30/22);
      color: $desc-color;
      @include mq(desktop) {
        line-height: (20/12);
      }
    }
  }

  &-indent {
    position: relative;
    padding-left: (37px/2);
    @include mq(desktop) {
      left: 22px;
    }
    &.no-unit {
      padding-left: (21px/2);
      @include mq(desktop) {
        left: 12px;
      }
    }
    &_unit {
      position: absolute;
      left: (21px/2);
      top: 0;
      display: inline-block;
      @include mq(desktop) {
        left: 12px;
      }
    }
  }
}

// contact privacy policy
.q {
  &-desc {
    padding-bottom: 30px;
    font-size: 14px;
    font-weight: 400;
    color: $desc-color;
    line-height: (22/14);
  }
  &-sub {
    padding-bottom: 38px;
    font-size: 12px;
    &_desc {
      padding-left: 12px;
      font-size: 14px;
      font-weight: 400;
      color: $desc-color;
      line-height: (22/14);
    }
  }

  &-1 {
    padding-bottom: 38px;
    font-size: 12px;
    border-top: 1px solid rgba($point-color, .5);
    &_tit {
      padding-top: 13px;
      padding-bottom: 22px;
      font-weight: 500;
      line-height: 1;
      color: $point-color;
      font-size: 14px;
    }
    &_desc {
      color: $desc-color;
      line-height: (20/12);
      & ~ & {
        margin-top: 14px;
        &.no-unit {
          margin-top: 0;
        }
      }
    }
  }

  &-2 {

  }

  &-indent {
    position: relative;
    padding-left: 32px;
    .q-2 & {
      padding-left: 21px;
    }
    .no-unit & {
      padding-left: 41px;
    }
    &.no-unit {
      padding-left: 12px;
    }
    &_unit {
      position: absolute;
      left: 12px;
      top: 0;
      display: inline-block;
      .q-2 & {
        left: 8px;
      }
      .no-unit & {
        left: 28px;
      }
    }
  }

}