html, body {
  width: 100%;
  height: auto;
  @include mq(tablet) {
    height: 100%;
  }
}
html {
  font-size: 62.5%;
  font-size: (1000vmin/375);
  @include mq(tablet) {
    font-size: 1.302084vmin;
  }
  @include mq(desktop) {
    font-size: 10px;
  }
}
body {
  font-size: 12px;
  -webkit-text-size-adjust: none;
}
.container {
  width: 100%;
  padding: 0;
  text-align: center;
  overflow: hidden;
  @include grid(rgba(217, 217, 217, .25));
  &--active{
    transform: translate3d($mobile-menu-width,0,0);
    transition: transform 450ms cubic-bezier(.45,.035,.045,.95);
    @include mq(tablet) {
      transform: translate3d(0,0,0);
    }
  }
}
.layout {
  &-wrapper {
    position: relative;
    width: auto;
    @include mq(wide) {
      width: 100%;
      max-width: $layout-wide-width;
      margin: 0 auto;
    }
  }
}
a[ng-click],
button[ng-click] {
  cursor: pointer;
}