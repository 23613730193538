$btn-height: (80px/2);
$desktop-btn-height: 42px;

$btn-style: (
    (
      'name': 'white',
      'background-color': $white-color,
      'border': 1px solid #d9d9d9,
      'color': #666666,
      'active-bg': rgba($point-bright, 0.7),
      'active-border': 1px solid rgba(255,255,255,.2),
      'active-color': #fefefe,
    ),
    (
      'name': 'white-popup',
      'background-color': $white-color,
      'border': 1px solid #eeeeee,
      'color': rgba(153,153,153,.8),
      'active-bg': rgba($point-bright, 0.7),
      'active-border': 1px solid rgba(255,255,255,.2),
      'active-color': #ffffff,
    ),
    (
      'name': 'white-cancel',
      'background-color': $white-color,
      'border': 1px solid #eeeeee,
      'color': rgba(153,153,153,.8),
      'active-bg': #eeeeee,
      'active-border': 1px solid #eeeeee,
      'active-color': rgba(153,153,153,.8),
    ),
    (
      'name': 'blue',
      'background-color': $point-color,
      'border': 1px solid rgba(255,255,255,0),
      'color': $white-color,
      'active-bg': #3e65b3,
      'active-border': 1px solid rgba(255,255,255,0),
      'active-color': $white-color,
    ),
    (
      'name': 'blue-popup',
      'background-color': $point-color,
      'border': 1px solid rgba(255,255,255,0),
      'color': rgba($white-color,.9),
      'active-bg': #4b6db2,
      'active-border': 1px solid rgba($white-color,.2),
      'active-color': rgba($white-color,.9),
    ),
    (
      'name': 'clear',
      'background-color': rgba($white-color,.1),
      'border': 1px solid rgba($white-color,.2),
      'color': rgba($white-color,.7),
      'active-bg': rgba($point-color, 0.7),
      'active-border': 1px solid rgba($white-color,.2),
      'active-color': rgba($white-color,.8),
    ),
    (
      'name': 'clear-popup',
      'background-color': rgba($white-color,.1),
      'border': 1px solid rgba($white-color,.2),
      'color': rgba($white-color,.8),
      'active-bg': rgba($point-color,.7),
      'active-border': 1px solid rgba($white-color,.2),
      'active-color': $white-color,
    ),
    (
      'name': 'black',
      'background-color': rgba(#000000,.4),
      'border': 0,
      'color': rgba(255,255,255,.6),
      'active-bg': rgba(#000000,.7),
      'active-border': 0,
      'active-color': rgba($white-color,.6),
    ),
);

$btn-width: (
    (
      'name': 'normal',
      'mobile-width': (256px/2),
      'desktop-width': 142px,
    ),
    (
      'name': 'single',
      'mobile-width': (288px/2),
      'desktop-width': 154px,
    ),
    (
      'name': 'double',
      'mobile-width': (256px/2),
      'desktop-width': 124px,
    ),
    (
      'name': 'rect',
      'tablet-width': 30%,
      'desktop-width': 100px,
    ),
    (
      'name': 'arrow-right',
      'mobile-padding': 0 (48px/2),
      'desktop-padding': 0 20px 0 26px,
    ),
    (
      'name': 'arrow-left',
      'mobile-padding': 0 (48px/2),
      'desktop-padding': 0 26px 0 20px,
    ),
);

.btn {
  display: inline-block;
  height: $btn-height;
  padding: 0;
  font-family: $default-font;
  font-size: 0;
  font-weight: 600;
  text-align: center;
  letter-spacing: normal;
  line-height: $btn-height - 2px;
  vertical-align: middle;
  border: 0;
  transition: background-color 300ms, color 300ms;
  @include border-radius($btn-height/2);
  @include mq(tablet) {
    height: $desktop-btn-height;
    line-height: $desktop-btn-height - 2px;
    @include border-radius($desktop-btn-height/2);
  }

  // btn style
  @each $style in $btn-style {
    &.btn-#{map_get($style,'name')} {
      color: map_get($style, 'color');
      background-color: map_get($style, 'background-color');
      border: map_get($style, 'border');
      &:hover {
        @if map-has-key($style, 'active-color') {
          color: map_get($style, 'active-color');
        }
        @if map-has-key($style, 'active-bg') {
          background-color: map_get($style, 'active-bg');
        }
        border: map_get($style, 'active-border');
      }
    }
  }

  // btn width
  @each $width in $btn-width {
    &.btn-#{map_get($width, 'name')} {
      @if map-has-key($width, 'mobile-width') {
        width: map_get($width, 'mobile-width');
      }
      @if map-has-key($width, 'mobile-padding') {
        padding: map_get($width, 'mobile-padding')
      }
      @include mq(tablet) {
        @if map-has-key($width, 'tablet-width') {
          width: map_get($width, 'tablet-width');
        }
      }
      @include mq(desktop) {
        @if map-has-key($width, 'desktop-width') {
          width: map_get($width, 'desktop-width');
        }
        @if map-has-key($width, 'desktop-padding') {
          padding: map_get($width, 'desktop-padding')
        }
      }
    }
  }

  &.btn-rect {
    font-size: 12px;
    border-radius: 0;
    vertical-align: top;
    line-height: $desktop-btn-height;
    @include mq(tablet) {
      position: relative;
      z-index: 10;
    }
  }

  // btn text & arrow
  &-text {
    display: inline-block;
    font-size: 13px;
    @include mq(tablet) {
      font-size: 14px;
    }
  }
  &-icon {
    display: inline-block;
    width: (27px/2);
    height: (22px/2);
    text-indent: -999px;
    overflow: hidden;
    font-size: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
    @include mq(tablet) {
      width: 14px;
      height: 12px;
    }
    &__right {
      margin-left: (26px/2);
      @include icon-style('ic-arrow-01.svg');
      .btn-white-popup & {
        @include icon-style('ic-arrow-02.svg');
      }
      .btn-white-popup:hover & {
        @include icon-style('ic-arrow-01.svg');
      }
      .btn:hover & {
        animation: btn-animate-right;
        animation-duration: 400ms;
        animation-iteration-count: 1;
      }
    }
    &__left {
      margin-right: (26px/2);
      transform: scale(-1, 1);
      @include icon-style('ic-arrow-01.svg');
      .btn-white-popup & {
        @include icon-style('ic-arrow-02.svg');
        transform: scale(-1, 1);
      }
      .btn-white-popup:hover & {
        @include icon-style('ic-arrow-01.svg');
        transform: scale(-1, 1);
      }
      .btn:hover & {
        animation: btn-animate-left;
        animation-duration: 400ms;
        animation-iteration-count: 1;
      }
    }
    @include mq(tablet) {
      width: 14px;
      height: 12px;
      &__right {
        margin-left: 28px;
      }
      &__left {
        margin-right: 28px;
      }
    }
  }


  // btn layout
  &-wrap {
    font-size: 0;
  }
  & ~ & {
    margin-left: (22px/2);
    @include mq(tablet) {
      margin-left: 22px;
    }
  }
  &-double ~ &-double {
    @include mq(tablet) {
      margin-left: 8px;
    }
  }
}

@keyframes btn-animate-right {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes btn-animate-left {
  0% {
    transform: translateX(0) scale(-1,1);
  }
  50% {
    transform: translateX(-5px) scale(-1,1);
  }
  100% {
    transform: translateX(0) scale(-1,1);
  }
}